@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700;900&display=swap);
.ArtContent {
  width: 100%;
  height: 4300px;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
}

.DefaultText {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  margin-top: 50px;
}

.ArtBox {
  width: 273px;
  height: 490px;
  border: 1px solid rgb(199, 199, 199);
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: -70px;
}

.atitle {
  text-align: left;
  margin-left: 10px;
  font-family: "GmarketSansLight";
  font-weight: bolder;
  font-size: 14px;
  color: rgb(99, 99, 99);
}

.adescp {
  text-align: left;
  margin-left: 10px;
  margin-top: -3px;
  font-family: "GmarketSansLight";
  font-size: 12px;
  color: black;
  padding-right: 5px;
}

.ArtWide {
  width: 450px;
  height: 490px;
  border: 1px solid rgb(199, 199, 199);
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: -70px;
}

.ArtVideo {
  width: 450px;
  height: 430px;
  border: 1px solid rgb(199, 199, 199);
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.ArtWide img {
  margin-bottom: 15px;
}
.ArtVideo video {
  margin-bottom: 15px;
}

.Youtube_api {
  width: 450px;
  height: 225px;
  margin-bottom: 15px;
}

.ArtContent ul {
  list-style: none;
  display: inline-flex;
  padding-bottom: 100px;
}

.ArtContent ul li {
  margin-right: 20px;
}

.ATitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  margin-top: -10px;
  margin-bottom: 100px;
}

.Artinfo {
  margin-top: 20px;
}

.ModalClick {
  cursor: pointer;
  width: 450px;
  height: 253px;
  overflow: hidden;
}

.ModalClick img {
  width: 450px;
  height: 253px;
  transition: 0.3s all;
}

.ModalClick:hover img {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  -webkit-filter: brightness(130%);
          filter: brightness(130%);
  overflow: hidden;
}

.ModalClickS {
  cursor: pointer;
  width: 273px;
  height: 273px;
  overflow: hidden;
  text-align: center;
  background-color: #151515;
}

.ModalClickS img {
  height: 100%;
  transition: 0.3s all;
}

.ModalClickS:hover img {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  overflow: hidden;
}

.ModalClick img:hover .ModalBox {
  display: block;
  z-index: 21;
}

.ModalBox {
  width: 70%;
  justify-content: center;
  align-items: center;
  border: none;
}

.ModalBoxS {
  width: 33%;
  justify-content: center;
  align-items: center;
  border: none;
}

.ModalBox img {
  display: block;
  margin: 0;
}

.ModalBoxS img {
  display: block;
  margin: 0;
}

@media (max-width: 768px) {
  .ArtContent ul {
    display: inline-table;
  }

  .ArtContent ul li {
    margin-bottom: 150px;
  }

  .ArtBox {
    width: 250px;
    height: 250px;
  }

  .ArtVideo {
    width: 250px;
    height: 250px;
  }

  .ArtWide img {
    width: 273px;
    height: auto;
  }

  .ArtVideo video {
    width: 273px;
    height: auto;
  }

  .ArtWide {
    margin-top: -130px;
    margin-bottom: 150px;
    align-content: center;
    width: 250px;
    height: auto;
  }

  .ArtContent {
    height: auto;
  }

  .ModalClick {
    width: 273px;
  }

  .ModalBox {
    width: 90%;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .ModalBoxS {
    width: 90%;
    justify-content: center;
    align-items: center;
    border: none;
  }
}

.parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

  
@font-face {
  font-family: "GmarketSansLight";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GmarketSansBold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
  color: black;
}
a:link {
  text-decoration: none;
  color: black;
}
a:active {
  text-decoration: none;
  color: black;
}
a:hover {
  text-decoration: none;
  color: black;
}

.App {
  height: 9500px;
}

.Default {
  width: 100%;
  height: 100vh;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
}

.DefaultText {
  font-family: "GmarketSansBold", sans-serif;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 0px;
  margin-left: 50px;
}

.WelcomeDefault {
  width: 100%;
  height: 100vh;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
  background-image: url(/static/media/bg.c67879e3.png);
  background-position: 0 0;
  background-position: initial;
  background-size: cover;
  -webkit-filter: invert(7%);
          filter: invert(7%);
}

.welcomeDefaultDesc {
  text-align: center;
  font-family: "GmarketSansMedium";
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 15px;
}

.ResumeDefault {
  text-align: center;
}

.IDDefault {
  width: 100%;
  height: auto;
  text-align: center;
  vertical-align: middle;
}

.IDDDefault {
  width: 100%;
  height: 110vh;
  padding-top: 10vh;
  text-align: center;
  vertical-align: middle;
}

.IDDDefaultText {
  text-align: center;
  font-family: "GmarketSansBold", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 127px;
}

.IDDDefaultDesc {
  text-align: center;
  font-family: "GmarketSansMedium", sans-serif;
  font-weight: 400;
  letter-spacing: -1.7px;
  font-size: 20px;
}

.KDefault {
  width: 100%;
  height: 100vh;
  /* border: 1px solid #000; */
  text-align: center;
  vertical-align: middle;
}

.KDefault div {
  display: inline-block;
}

.KDefaultText {
  font-family: "GmarketSansBold", sans-serif;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 100px;
}

.keywordbox {
  width: 140px;
  height: 50px;
  border: 1px solid #eb1d47;
  border-radius: 50px;
  text-align: center;
  margin: 5px 5px;
  background-color: rgba(235, 29, 71, 0.05);
}

.keywordbox p {
  height: 50px;
  text-align: center;
  margin-top: 15.5px;
  color: #eb1d47;
  font-family: "GmarketSansMedium";
  font-size: 13.5px;
}

.subkeywordbox {
  width: 140px;
  height: 50px;
  border: 1px solid rgb(90, 90, 90);
  border-radius: 50px;
  text-align: center;
  margin: 5px 5px;
  background-color: white;
}

.subkeywordbox p {
  height: 50px;
  text-align: center;
  margin-top: 15.5px;
  color: rgb(90, 90, 90);
  font-family: "GmarketSansMedium";
  font-size: 13.5px;
}

.Kdescdiv {
  margin-top: 50px;
}

.Kdesc {
  text-align: center;
  font-family: "GmarketSansMedium";
  font-weight: 400;
  letter-spacing: -1px;
  font-size: 15px;
  margin-top: 30px;
}

p {
  margin-top: -10px;
}

video {
  width: 100%;
  z-index: -1;
}

.ContactDefault {
  width: 100%;
  height: 500px;
  text-align: center;
  vertical-align: middle;
  background-color: rgb(245, 245, 245);
  padding-bottom: 100px;
}

.ContactText {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 30px;
  padding-top: 30px;
  color: black;
}

.welcome {
  align-items: center;
  list-style: none;
  display: inline-flex;
  font-family: "GmarketSansBold";
  font-weight: 900;
  text-align: center;
  font-size: 60px;
  color: black;
  margin-top: 350px;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.test li {
  margin-left: 50px;
}

.Flag_KOR {
  align-content: center;
  vertical-align: middle;
}

.contacticon {
  justify-content: space-evenly;
  display: inline-flex;
  text-align: center;
  width: 70%;
}

.contacticon li {
  display: inline-block;
}

.contacticon li p {
  color: balck;
  font-family: "Montserrat";
  font-weight: 200;
  font-size: 13px;
  margin-top: 5px;
}

.B2T {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
}

.B2T img {
  -webkit-filter: invert(80%);
          filter: invert(80%);
  margin-top: 30px;
  transition: all 0.2s ease-in-out;
}

.B2T:hover img {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

.Footer p {
  font-family: "Montserrat", sans-serif;
  margin-top: 50px;
}

.Footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding-top: 1px;
  padding-bottom: 10px;
  margin-top: -10px;
  z-index: 1;
  background-color: white;
  -webkit-filter: drop-shadow(0 2mm 10mm rgb(223, 223, 223));
          filter: drop-shadow(0 2mm 10mm rgb(223, 223, 223));
}

.Footer_logo {
  -webkit-filter: contrast(0%);
          filter: contrast(0%);
  margin-top: 35px;
  display: block;
  margin-left: 30px;
}

.footerinfo {
  text-align: left;
  margin-top: -45px;
  margin-left: 120px;
  font-size: 13px;
  font-family: "Montserrat";
  font-weight: 200;
}

.langsel {
  text-align: right;
  margin-right: 20px;
  margin-top: -30px;
  font-family: "Montserrat";
}

@media (max-width: 768px) {
  .footerinfo {
    font-size: 7px;
    margin-left: 100px;
    margin-top: -32px;
  }

  .Footer_logo {
    width: 50px;
  }

  .langsel {
    margin-top: -25px;
    margin-right: 20px;
  }

  .IDDDefault {
    padding-bottom: 150px;
  }

  .IDDDefaultDesc {
    font-size: 17px;
    text-align: left;
  }

  .IDDDefaultText {
    margin-left: 20px;
    text-align: left;
  }

  .IDDDefaultDesc {
    font-size: 17px;
    margin-left: 20px;
    padding-right: 30px;
  }

  .KDefault {
    height: auto;
  }

  .KDefaultText {
    margin-left: 0px;
  }

  .Kdesc {
    padding-right: 30px;
    padding-left: 30px;
    text-align: left;
  }

  .contacticon {
    justify-content: space-evenly;
    display: inline;
    width: 100%;
  }

  .contacticon img {
    width: 50px;
  }

  .contacticon li {
    margin-left: 3px;
    margin-right: 3px;
  }

  .contacticon li p {
    font-size: 7px;
  }

  .ResumeDefault img {
    width: 90%;
  }
}

